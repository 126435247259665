import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import './App.css';
import headerImage from './images/elainpalvelut-background.jpg';
import logoImage from './images/lemmikkipuatilogo-koira-small.jpg';
import fbIcon from './images/fb-icon.png';
import jyrsijat from './images/jyrsijat.png'
import matelijat from './images/matelijat.JPG'
import hyonteiset from './images/hyonteiset.jpg'
import linnut from './images/linnut.jpg'
import koulutushalli from './images/koulutushalli.JPG'
import kouluttaja from './images/kouluttaja.jpg'
import instaIcon from './images/insta-icon.png'; 
import SiteButton from './images/vorssan-lemmikkipuati-button.png'
import HomeButton from './images/puadin-elainpalvelut-button.png'

const KoulutushallijaKoulutukset = () => {
  return (
    <div>
      <div className="logo-btn-image">
        <Link to="/"><img src={HomeButton} alt="Puadin Eläinpalvelut"/></Link>
        <a href="https://www.lemmikkipuati.fi" alt="Vorssan Lemmikkipuati" target="_blank"><img src={SiteButton} alt="Vorssan Lemmikkipuati"/></a>
      </div>
      <header>
      
        <div className="header-image">
          <img src={headerImage} alt="Header" />
        </div>
      </header>
      <main className="main-container">
        <div className="left-section">

        <div class="breadcrumbs">
        <Link to="/">Etusivu</Link>
  <span class="separator">/</span>
  <span class="current-page">Koulutushalli ja Koulutukset</span>
</div>


<div class="image-text-container">
<div class="image-item">
    <img src={koulutushalli} alt="koulutushalli" />
          </div>
          <div className="text-item">  <h2>Koulutushalli</h2>
           <p>Hallissa järjestetään omia koulutuksia sekä asiakastapahtumia. Hallin koko on 12 x 8 metriä ja pohjana on kokolattiamatto. Hallista löytyy wc.
</p>
<p>Hallia vuokrataan myös yksityiskäyttöön</p>

<p>Vuokraushinnasto:</p>

<p>1 - 2 koiraa 10 € / tunti</p>

<p>3 - 4 koiraa 15 € / tunti</p>

<p>5 + koiraa 20 € / tunti</p>

<p>Ilmoittaudu kursseille, yksityistunneille tai tiedustele myös koko päivän vuokrauksia 045 119 7557 tai elainpalvelut@lemmikkipuati.fi</p>

<p>Hallin osoite on Sepänkatu 1, 30100, Forssa</p></div></div>



        </div>
      
        <div className="right-section">
          <p>
            <b>Yhteystiedot</b>
            <br />
            Vorssan Lemmikkipuadin tiloissa<br></br>
            Sepänkatu 1 <br />
            30100 Forssa
          </p>
          <p>
            Meidät tavoittaa puhelimitse ja sähköpostitse:
            <br />
            <b>045 119 7557</b>
            <br />
            <b>elainpalvelut@lemmikkipuati.fi</b>
          </p>
          <p>
            <b>Seuraa meitä sosiaalisessa mediassa:</b>
          </p>
          <p>
            Vorssan Lemmikkipuati
            <br />
            <a href="https://www.facebook.com/vorssanlemmikkipuati/" target="_blank" rel="noopener noreferrer">
              <img src={fbIcon} alt="Vorssan lemmikkipuati FB" />
            </a>

            <a href="https://www.instagram.com/vorssanlemmikkipuati/" target="_blank" alt="insta"><img src={instaIcon} alt="Vorssan lemmikkipuati insta"/></a>
          </p>
          <p>
            Puadin Eläinpalvelut
            <br />
            <a href="https://www.facebook.com/puadinelainpalvelut" target="_blank" rel="noopener noreferrer">
              <img src={fbIcon} alt="Puadin eläinpalvelut FB" />
            </a>
            <a href="https://www.instagram.com/puadinelainpalvelut/" target="_blank" alt="insta"><img src={instaIcon} alt="Puadin elainpalvelut insta"/></a>
          </p>
        </div>
      </main>

      <div className="bottom-section">

        <div className="bottom-box">
          <div className="image-text-container">
          <div className="inner-left">
            <div className="image-item-left">
              <img src={kouluttaja} alt="kouluttaja"/> </div>
              <div className="text-item-left">
                <p><h3 className="mb-0">Kurssit</h3>
                Alkavat kurssit voit tarkistaa <Link to="/">etusivun</Link> tapahtumakalenterista.</p>
              </div>
             
             
           
          </div>

          <div className="inner-right">
          <div className="text-item">
    <p><h3>Kouluttajasta</h3></p><p>
      Olen Paula Ojanen ja työtehtäviini kuuluu koirankoulutuskurssien suunnittelu ja niiden toteuttaminen Vorssan Lemmikkipuadilla. Minulta voi varata myös yksityistunteja.</p>
      <p>Olen valmistunut eläintenhoitajaksi vuonna 2012 ja eläintenkouluttajaksi vuonna 2020.<br></br> Kouluttajana suosin positiiviseen vahvistamiseen perustuvaa koulutustapaa. 
      Mielestäni tärkeintä harrastamisessa on se, että ohjaajan ja koiran yhteistyö sujuu ja treenien jälkeen molemmilla on hyvä mieli. 
      Kuuntelen koulutettavien mielipiteitä ja toiveita ja etenemme koulutuksessa aina sitä tahtia, joka on koirakolle sopiva. 
      On aina erittäin hienoa ja palkitsevaa katsoa, kun koirakot kehittyvät, saavat onnistumisia ja yhteistyö sujuu!</p>
       <p>
      Minulta löytyy kokemusta erilaisten koirakoiden kouluttamisesta pitkältä ajalta. 
      Kursseja olen vetänyt monia ja lisäksi olen kouluttanut agilitya nyt jo muutaman vuoden ajan.</p>
      <p>Ensimmäisen koirani sain vuonna 2014 ja sen jälkeen koirat ovat olleet aina iso osa elämääni. 
      <br></br>Erilaisia lajeja on tullut kokeiltua monia, viime vuosina olen keskittynyt lähinnä agilityyn ja rally-tokoon. 
      <br></br>Tällä hetkellä kotona on kasvamassa labradorinnoutajapentu Ulla (kuvassa oikealla), jonka kanssa toiveissa olisi tulevaisuudessa harrastaa agilitya, rally-tokoa, vesipelastusta ja canicrossia. 
      Kotoa löytyy myös toinen labradorinnoutaja Kerttu (kuvassa vasemmalla), jonka kanssa kisaan tällä hetkellä agilityssa.</p>

  </div>


          </div>
          </div>
        </div>
 
  </div>
      
      <footer>
        <p>
        Sepänkatu 1, Forssa | 045 119 7557 | elainpalvelut@lemmikkipuati.fi © 2023 Puadin Eläinpalvelut
        </p>
      </footer>
    </div>
  );
};

const PalvelutjaHinnasto = () => {
  return (
    <div>
      <div className="logo-btn-image">
        <Link to="/"><img src={HomeButton} alt="Puadin Eläinpalvelut"/></Link>
        <a href="https://www.lemmikkipuati.fi" alt="Vorssan Lemmikkipuati" target="_blank"><img src={SiteButton} alt="Vorssan Lemmikkipuati"/></a>
      </div>
      <header>
        <div className="header-image">
          <img src={headerImage} alt="Header" />
        </div>
      </header>
      <main className="main-container">
        <div className="left-section">

        <div class="breadcrumbs">
        <Link to="/">Etusivu</Link>
  <span class="separator">/</span>
  <span class="current-page">Palvelut ja Hinnasto</span>
</div>



          <h2>Palvelut ja Hinnasto</h2>
          <p>Hoitolaamme voit tuoda täysihoitoon erilaisia jyrsijöitä, matelijoita, hyönteisiä ja lintuja. <br></br> Kaikista hoitoon tuoduista eläimistä tehdään kirjallinen hoitosopimus. <p>
          Tarjoamme myös kynsien leikkuuta kissoille, koirille sekä jyrsijöille, hinta 10 € / eläin tai 20 € / eläin, <br></br>jos leikkaaminen on vaativaa.</p>
          Ole yhteydessä meihin ja kysy myös kissojen ja akvaarioiden kotihoitopalveluistamme.</p>
          <div class="image-container">
  <div class="image-item-small-1">
    <img src={jyrsijat} alt="jyrsijat" />
    <p><b>Kanit, marsut, rotat, degut, chinchillat</b></p>
      <p>Hinta 50 € / viikko, yli menevät vuorokaudet <br></br>10 € / vrk</p> 
      <p><b>Hiiret, hamsterit, gerbiilit</b> </p>
      <p>Hinta 35 € / viikko, yli menevät vuorokaudet <br></br>7 € / vrk</p>
  </div>
  <div class="image-item-small-1">
    <img src={matelijat} alt="matelijat" />
    <p><b>Matelijat</b></p>
    <p>Hinta 50 € / viikko, yli menevät vuorokaudet<br></br> 10 € / vrk</p>
  </div>
  <div class="image-item-small-1">
    <img src={hyonteiset} alt="hyonteiset" />
    <p><b>Hyönteiset</b></p>
    <p>Hinta 10 € / viikko</p>
  </div>
  <div class="image-item-small-1">
    <img src={linnut} alt="linnut" />
    <p><b>Linnut</b></p>
    <p>Hinta 50 € / viikko, yli menevät vuorokaudet<br></br> 10 € / vrk</p>
  </div>
</div>

        </div>
        <div className="right-section">
          <p>
            <b>Yhteystiedot</b>
            <br />
            Vorssan Lemmikkipuadin tiloissa<br></br>
            Sepänkatu 1 <br />
            30100 Forssa
          </p>
          <p>
            Meidät tavoittaa puhelimitse ja sähköpostitse:
            <br />
            <b>045 119 7557</b>
            <br />
            <b>elainpalvelut@lemmikkipuati.fi</b>
          </p>
          <p>
            <b>Seuraa meitä sosiaalisessa mediassa:</b>
          </p>
          <p>
            Vorssan Lemmikkipuati
            <br />
            <a href="https://www.facebook.com/vorssanlemmikkipuati/" target="_blank" rel="noopener noreferrer">
              <img src={fbIcon} alt="Vorssan lemmikkipuati FB" />
            </a>

            <a href="https://www.instagram.com/vorssanlemmikkipuati/" target="_blank" alt="insta"><img src={instaIcon} alt="Vorssan lemmikkipuati insta"/></a>
          </p>
          <p>
            Puadin Eläinpalvelut
            <br />
            <a href="https://www.facebook.com/puadinelainpalvelut" target="_blank" rel="noopener noreferrer">
              <img src={fbIcon} alt="Puadin eläinpalvelut FB" />
            </a>
            <a href="https://www.instagram.com/puadinelainpalvelut/" target="_blank" alt="insta"><img src={instaIcon} alt="Puadin elainpalvelut insta"/></a>
          </p>
        </div>
      </main>
      
      <footer>
        <p>
        Sepänkatu 1, Forssa | 045 119 7557 | elainpalvelut@lemmikkipuati.fi © 2023 Puadin Eläinpalvelut
        </p>
      </footer>
    </div>
  );
};


const Home = () => {
  return (
    <div>
      
      <div className="logo-btn-image">
        <Link to="/"><img src={HomeButton} alt="Puadin Eläinpalvelut"/></Link>
        <a href="https://www.lemmikkipuati.fi" alt="Vorssan Lemmikkipuati" target="_blank"><img src={SiteButton} alt="Vorssan Lemmikkipuati"/></a>
      </div>

      <header>      
       
        <div className="header-image">
          <img src={headerImage} alt="Header" />
        </div>
      </header>
      <main className="main-container">
        <div className="left-section">
          <h2>Eksoottisten eläinten hoitola Käpälä & Suomu</h2>
          <p>
            Käpälä & Suomu on eksoottisten eläinten hoitoon erikoistunut hoitola. Työntekijämme ovat koulutettuja eläintenhoitajia, joilla on myös omaa kokemusta erilaisten jyrsijöiden, lintujen, matelijoiden ja hyönteisten hoidosta. <br />
            Hoitolamme sijaitsee Forssassa, Vorssan Lemmikkipuadin tiloissa.
          </p>
          <div className="in-container">
            <div className="left-in">
              <div>
                <iframe
                  className="kartta"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1945.699986052298!2d23.61944477688584!3d60.812689891414855!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x468e9cb5f9a8b4e5%3A0xed14c63828350f8c!2sSep%C3%A4nkatu%201%2C%2030100%20Forssa!5e0!3m2!1sfi!2sfi!4v1686578777383!5m2!1sfi!2sfi"
                ></iframe>
              </div>
            </div>
            <div className="right-in">
              <h2>Katso lisätiedot:</h2>
              <p>
                <Link to="/PalvelutjaHinnasto" className="button-link">
                  Palvelut ja Hinnasto
                </Link>
              </p>
              <p>
                <Link to="/KoulutushallijaKoulutukset" className="button-link">
                  Koulutushalli ja Koulutukset
                </Link>
              </p>
            </div>
          </div>
        </div>
        <div className="right-section">
          <p>
            <b>Yhteystiedot</b>
            <br />
            Vorssan Lemmikkipuadin tiloissa<br></br>
            Sepänkatu 1 <br />
            30100 Forssa
          </p>
          <p>
            Meidät tavoittaa puhelimitse ja sähköpostitse:
            <br />
            <b>045 119 7557</b>
            <br />
            <b>elainpalvelut@lemmikkipuati.fi</b>
          </p>
          <p>
            <b>Seuraa meitä sosiaalisessa mediassa: </b>
          </p>
          <p>
            Vorssan Lemmikkipuati
            <br />
            <a href="https://www.facebook.com/vorssanlemmikkipuati/" target="_blank" rel="noopener noreferrer">
              <img src={fbIcon} alt="Vorssan lemmikkipuati FB" />
            </a>

            <a href="https://www.instagram.com/vorssanlemmikkipuati/" target="_blank" alt="insta"><img src={instaIcon} alt="Vorssan lemmikkipuati insta"/></a>
          </p>
          <p>
            Puadin Eläinpalvelut
            <br />
            <a href="https://www.facebook.com/puadinelainpalvelut" target="_blank" rel="noopener noreferrer">
              <img src={fbIcon} alt="Puadin eläinpalvelut FB" />
            </a>
            <a href="https://www.instagram.com/puadinelainpalvelut/" target="_blank" alt="insta"><img src={instaIcon} alt="Puadin elainpalvelut insta"/></a>
          </p>
        </div>
      </main>
      <div className="bottom-section">
        <section>
          <div className="info-gallery">
            <div className="info-card">
              <h3>Kanta-asiakkuus</h3>
              <p>
                Liity nyt Vorssan Lemmikkipuadin kanta-asiakkaaksi ja voit voittaa lahjakortin liikkeeseen!
                Tervetuloa mukaan Vorssan Lemmikkipuadin uuteen kanta-asiakasohjelmaan: Puatilaisiin!
              </p>
              <p>Klikkaa kuvasta liittymislomakkeeseen!</p>
              <div className="logo-image">
                <a href="https://nano.paljon.fi/lemmikkipuati/kampanjat/kantis" target="_blank" rel="noopener noreferrer">
                  <img src={logoImage} alt="puatilainen" />
                </a>
              </div>
            </div>
            <div className="info-card">
              <h3>Tapahtumakalenteri</h3>
              
              <iframe className="kalenteri" src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=Europe%2FHelsinki&src=cHVhZGluZWxhaW5wYWx2ZWx1dEBnbWFpbC5jb20&color=%23039BE5"></iframe>

            </div>
          </div>
        </section>
      </div>
      <footer>
        <p>
        Sepänkatu 1, Forssa | 045 119 7557 | elainpalvelut@lemmikkipuati.fi © 2023 Puadin Eläinpalvelut
        </p>
      </footer>
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/PalvelutjaHinnasto" element={<PalvelutjaHinnasto />} />

        <Route path="/KoulutushallijaKoulutukset" element={<KoulutushallijaKoulutukset />} />
      </Routes>
    </Router>
  );
};

export default App;
